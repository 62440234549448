@import 'themes';

@import '~@nebular/theme/styles/globals';

@include nb-install() {
  @include nb-theme-global();
};

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sharecheckapp-primary: mat-palette($mat-indigo);
$sharecheckapp-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$sharecheckapp-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sharecheckapp-theme: mat-light-theme((
  color: (
    primary: $sharecheckapp-primary,
    accent: $sharecheckapp-accent,
    warn: $sharecheckapp-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($sharecheckapp-theme);

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@import '~@nebular/theme/styles/globals';

$green :#06AB4D;

// html, body { 
//   height: 100%; 
// }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.sc-btn-primary{
  border-radius: 8px;
    padding: 10px;
    color: white;
    border: 1px solid lightgrey;
    box-shadow: 1px 2px 12px 1px lightgrey;
    background-color: #06AB4D;
    width: 100%;
    height: 40px;
}
.sc-btn-primary:disabled{
  border-radius: 8px;
    padding: 10px;
    color: #e6e6e6;
    border: 1px solid lightgrey;
    box-shadow: 1px 2px 12px 1px lightgrey;
    background-color: #9ba6a0;
    width: 100%;
    height: 40px;
}


/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.nb-theme-default nb-layout .layout{
  background-color: white !important;
}
.nb-theme-default nb-spinner.status-success .spin-circle{
  border-top-color: #000000!important;
  border-right-color: rgba(0,0,0,0)!important;
  border-bottom-color: #045827!important;
  border-left-color: #1ca85e!important;
}
.nb-theme-default nb-spinner .message{
  color: white !important;
  font-size: 0.775rem !important;
  letter-spacing: 1px !important;
}